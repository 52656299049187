﻿/* css fancytree v2.20 ou v2.21 */
/*!
 * Fancytree "bootstrap" skin.
 *
 * DON'T EDIT THE CSS FILE DIRECTLY, since it is automatically generated from
 * the LESS templates.
 */
/*******************************************************************************
 * Common Styles for Fancytree Skins.
 *
 * This section is automatically generated from the `skin-common.less` template.
 ******************************************************************************/
/*------------------------------------------------------------------------------
 * Helpers
 *----------------------------------------------------------------------------*/
.ui-helper-hidden {
  display: none;
}
/*------------------------------------------------------------------------------
 * Container and UL / LI
 *----------------------------------------------------------------------------*/
ul.fancytree-container {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  white-space: nowrap;
  padding: 3px;
  margin: 0;
  background-color: white;
  border: 1px dotted gray;
  overflow: auto;
  min-height: 0%;
  position: relative;
}
ul.fancytree-container ul {
  padding: 0 0 0 16px;
  margin: 0;
}
ul.fancytree-container ul > li:before {
  content: none;
}
ul.fancytree-container li {
  list-style-image: none;
  list-style-position: outside;
  list-style-type: none;
  -moz-background-clip: border;
  -moz-background-inline-policy: continuous;
  -moz-background-origin: padding;
  background-attachment: scroll;
  background-color: transparent;
  background-position: 0em 0em;
  background-repeat: repeat-y;
  background-image: none;
  margin: 0;
}
ul.fancytree-container li.fancytree-lastsib {
  background-image: none;
}
ul.fancytree-no-connector > li {
  background-image: none;
}
.ui-fancytree-disabled ul.fancytree-container {
  opacity: 0.5;
  background-color: silver;
}
/*------------------------------------------------------------------------------
 * Common icon definitions
 *----------------------------------------------------------------------------*/
span.fancytree-empty,
span.fancytree-vline,
span.fancytree-expander,
span.fancytree-icon,
span.fancytree-checkbox,
span.fancytree-radio,
span.fancytree-drag-helper-img,
#fancytree-drop-marker {
  width: 1em;
  height: 1em;
  display: inline-block;
  vertical-align: top;
  background-repeat: no-repeat;
  background-position: left;
  background-position: 0em 0em;
}
span.fancytree-icon,
span.fancytree-checkbox,
span.fancytree-expander,
span.fancytree-radio,
span.fancytree-custom-icon {
  margin-top: 2px;
}
/* Used by icon option: */
span.fancytree-custom-icon {
  width: 1em;
  height: 1em;
  display: inline-block;
  margin-left: 0.5em;
  background-position: 0em 0em;
}
/* Used by 'icon' node option: */
img.fancytree-icon {
  width: 1em;
  height: 1em;
  margin-left: 0.5em;
  margin-top: 2px;
  vertical-align: top;
  border-style: none;
}
/*------------------------------------------------------------------------------
 * Expander icon
 *
 * Note: IE6 doesn't correctly evaluate multiples class names,
 *		 so we create combined class names that can be used in the CSS.
 *
 * Prefix: fancytree-exp-
 * 1st character: 'e': expanded, 'c': collapsed, 'n': no children
 * 2nd character (optional): 'd': lazy (Delayed)
 * 3rd character (optional): 'l': Last sibling
 *----------------------------------------------------------------------------*/
span.fancytree-expander {
  cursor: pointer;
}
.fancytree-exp-n span.fancytree-expander,
.fancytree-exp-nl span.fancytree-expander {
  background-image: none;
  cursor: default;
}
/* Fade out expanders, when container is not hovered or active */
.fancytree-fade-expander span.fancytree-expander {
  transition: opacity 1.5s;
  opacity: 0;
}
.fancytree-fade-expander:hover span.fancytree-expander,
.fancytree-fade-expander.fancytree-treefocus span.fancytree-expander,
.fancytree-fade-expander .fancytree-treefocus span.fancytree-expander,
.fancytree-fade-expander [class*='fancytree-statusnode-'] span.fancytree-expander {
  transition: opacity 0.6s;
  opacity: 1;
}
/*------------------------------------------------------------------------------
 * Checkbox icon
 *----------------------------------------------------------------------------*/
span.fancytree-checkbox {
  margin-left: 0.5em;
}
.fancytree-unselectable span.fancytree-checkbox {
  opacity: 0.4;
  filter: alpha(opacity=40);
}
/*------------------------------------------------------------------------------
 * Radiobutton icon
 * This is a customization, that may be activated by overriding the 'checkbox'
 * class name as 'fancytree-radio' in the tree options.
 *----------------------------------------------------------------------------*/
/*------------------------------------------------------------------------------
 * Node type icon
 * Note: IE6 doesn't correctly evaluate multiples class names,
 *		 so we create combined class names that can be used in the CSS.
 *
 * Prefix: fancytree-ico-
 * 1st character: 'e': expanded, 'c': collapsed
 * 2nd character (optional): 'f': folder
 *----------------------------------------------------------------------------*/
span.fancytree-icon {
  margin-left: 0.5em;
}
/* Documents */
/* Folders */
.fancytree-loading span.fancytree-expander,
.fancytree-loading span.fancytree-expander:hover,
.fancytree-statusnode-loading span.fancytree-icon,
.fancytree-statusnode-loading span.fancytree-icon:hover {
  background-image: none;
}
/* Status node icons */
/*------------------------------------------------------------------------------
 * Node titles and highlighting
 *----------------------------------------------------------------------------*/
span.fancytree-node {
  /* See #117 */
  display: inherit;
  width: 100%;
  margin-top: 0px;
  min-height: 1em;
}
span.fancytree-title {
  color: #333333;
  cursor: pointer;
  display: inline-block;
  vertical-align: top;
  min-height: 1em;
  padding: 0 3px 0 3px;
  margin: 0px 0 0 0.5em;
  border: 1px solid transparent;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
}
span.fancytree-node.fancytree-error span.fancytree-title {
  color: #d9534f;
}
/*------------------------------------------------------------------------------
 * Drag'n'drop support
 *----------------------------------------------------------------------------*/
div.fancytree-drag-helper span.fancytree-childcounter,
div.fancytree-drag-helper span.fancytree-dnd-modifier {
  display: inline-block;
  color: #fff;
  background: #337ab7;
  border: 1px solid gray;
  min-width: 10px;
  height: 10px;
  line-height: 1;
  vertical-align: baseline;
  border-radius: 10px;
  padding: 2px;
  text-align: center;
  font-size: 9px;
}
div.fancytree-drag-helper span.fancytree-childcounter {
  position: absolute;
  top: -6px;
  right: -6px;
}
div.fancytree-drag-helper span.fancytree-dnd-modifier {
  background: #5cb85c;
  border: none;
  font-weight: bolder;
}
/*** Drop marker icon *********************************************************/
#fancytree-drop-marker {
  width: 2em;
  position: absolute;
  margin: 0;
}
#fancytree-drop-marker.fancytree-drop-after,
#fancytree-drop-marker.fancytree-drop-before {
  width: 4em;
}
/*** Source node while dragging ***********************************************/
span.fancytree-drag-source.fancytree-drag-remove {
  opacity: 0.15;
}
/*** Target node while dragging cursor is over it *****************************/
/*------------------------------------------------------------------------------
 * 'table' extension
 *----------------------------------------------------------------------------*/
table.fancytree-ext-table {
  border-collapse: collapse;
}
table.fancytree-ext-table span.fancytree-node {
  display: inline-block;
  box-sizing: border-box;
}
/*------------------------------------------------------------------------------
 * 'columnview' extension
 *----------------------------------------------------------------------------*/
table.fancytree-ext-columnview tbody tr td {
  position: relative;
  border: 1px solid gray;
  vertical-align: top;
  overflow: auto;
}
table.fancytree-ext-columnview tbody tr td > ul {
  padding: 0;
}
table.fancytree-ext-columnview tbody tr td > ul li {
  list-style-image: none;
  list-style-position: outside;
  list-style-type: none;
  -moz-background-clip: border;
  -moz-background-inline-policy: continuous;
  -moz-background-origin: padding;
  background-attachment: scroll;
  background-color: transparent;
  background-position: 0em 0em;
  background-repeat: repeat-y;
  background-image: none;
  /* no v-lines */
  margin: 0;
}
table.fancytree-ext-columnview span.fancytree-node {
  position: relative;
  /* allow positioning of embedded spans */
  display: inline-block;
}
table.fancytree-ext-columnview span.fancytree-node.fancytree-expanded {
  background-color: #CBE8F6;
}
table.fancytree-ext-columnview .fancytree-has-children span.fancytree-cv-right {
  position: absolute;
  right: 3px;
}
/*------------------------------------------------------------------------------
 * 'filter' extension
 *----------------------------------------------------------------------------*/
.fancytree-ext-filter-dimm span.fancytree-node span.fancytree-title {
  color: #333333;
  font-weight: lighter;
}
.fancytree-ext-filter-dimm tr.fancytree-submatch span.fancytree-title,
.fancytree-ext-filter-dimm span.fancytree-node.fancytree-submatch span.fancytree-title {
  color: black;
  font-weight: normal;
}
.fancytree-ext-filter-dimm tr.fancytree-match span.fancytree-title,
.fancytree-ext-filter-dimm span.fancytree-node.fancytree-match span.fancytree-title {
  color: black;
  font-weight: bold;
}
.fancytree-ext-filter-hide tr.fancytree-hide,
.fancytree-ext-filter-hide span.fancytree-node.fancytree-hide {
  display: none;
}
.fancytree-ext-filter-hide tr.fancytree-submatch span.fancytree-title,
.fancytree-ext-filter-hide span.fancytree-node.fancytree-submatch span.fancytree-title {
  color: #333333;
  font-weight: lighter;
}
.fancytree-ext-filter-hide tr.fancytree-match span.fancytree-title,
.fancytree-ext-filter-hide span.fancytree-node.fancytree-match span.fancytree-title {
  color: black;
  font-weight: normal;
}
.fancytree-ext-childcounter span.fancytree-icon,
.fancytree-ext-filter span.fancytree-icon {
  position: relative;
}
.fancytree-ext-childcounter span.fancytree-childcounter,
.fancytree-ext-filter span.fancytree-childcounter {
  color: #fff;
  background: #777;
  border: 1px solid gray;
  position: absolute;
  top: -6px;
  right: -6px;
  min-width: 10px;
  height: 10px;
  line-height: 1;
  vertical-align: baseline;
  border-radius: 10px;
  padding: 2px;
  text-align: center;
  font-size: 9px;
}
/*------------------------------------------------------------------------------
 * 'wide' extension
 *----------------------------------------------------------------------------*/
ul.fancytree-ext-wide {
  position: relative;
  min-width: 100%;
  z-index: 2;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
ul.fancytree-ext-wide span.fancytree-node > span {
  position: relative;
  z-index: 2;
}
ul.fancytree-ext-wide span.fancytree-node span.fancytree-title {
  position: absolute;
  z-index: 1;
  left: 0px;
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
/*******************************************************************************
 * Styles specific to this skin.
 *
 * This section is automatically generated from the `ui-fancytree.less` template.
 ******************************************************************************/
/*******************************************************************************
 * Plain tree
 * Modifier classes on <ul> container:
 * table-hover                : Enable a light mouse hover effect
 * fancytree-colorize-selected: Give selected (checked) rows a color
 */
ul.fancytree-container ul {
  padding: 0 0 0 1.5em;
  margin: 0;
}
/* Prevent focus frame */
.fancytree-container:focus {
  outline: none;
}
.fancytree-container .fancytree-active span.fancytree-title input,
.fancytree-container.fancytree-colorize-selected .fancytree-selected span.fancytree-title input {
  color: black;
}
.fancytree-container span.fancytree-statusnode-error span.fancytree-expander {
  color: #d9534f;
}
/* Helper to allow spinning loader icon with bootstrap */
.glyphicon-spin {
  -webkit-animation: spin 1000ms infinite linear;
  animation: spin 1000ms infinite linear;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
div.fancytree-drag-helper.fancytree-drop-reject,
div.fancytree-drag-helper.fancytree-drop-reject span.fancytree-title {
  color: #d9534f;
}
span.fancytree-node.fancytree-drag-source {
  background-color: #5bc0de !important;
}
span.fancytree-node.fancytree-drop-target.fancytree-drop-reject span.fancytree.title {
  background-color: #d9534f !important;
}
span.fancytree-expander {
  color: #999;
}
.fancytree-expanded span.fancytree-expander {
  color: #333333;
}
span.fancytree-node span.fancytree-expander:hover {
  color: cyan;
}
.fancytree-plain.fancytree-colorize-selected span.fancytree-node.fancytree-selected,
.fancytree-plain.fancytree-colorize-selected span.fancytree-node.fancytree-selected span.fancytree-title {
  background-color: #80c780;
  border-color: #80c780;
  color: #fff;
}
.fancytree-plain.fancytree-colorize-selected span.fancytree-node.fancytree-selected:hover span.fancytree-title {
  background-color: #6ec06e;
}
.fancytree-plain.fancytree-colorize-selected span.fancytree-node.fancytree-active.fancytree-selected span.fancytree-title {
  color: #80c780;
}
/*.fancytree-plain.fancytree-colorize-selected.fancytree-treefocus span.fancytree-title:hover {
  background-color: #f5f5f5;
}
.fancytree-plain.fancytree-colorize-selected.fancytree-treefocus span.fancytree-node.fancytree-selected span.fancytree-title {
  background-color: #5cb85c;
}
.fancytree-plain.fancytree-colorize-selected.fancytree-treefocus span.fancytree-node.fancytree-selected:hover span.fancytree-title {
  background-color: #4cae4c;
}
.fancytree-plain.fancytree-colorize-selected.fancytree-treefocus span.fancytree-node.fancytree-active.fancytree-selected span.fancytree-title {
  color: #5cb85c;
}*/
.fancytree-plain.fancytree-container span.fancytree-node {
  margin-top: 2px;
  margin-bottom: 2px;
}
.fancytree-plain.fancytree-container span.fancytree-title {
  border: 1px solid transparent;
  border-radius: 3px;
  outline-radius: 3px;
}
.fancytree-plain.fancytree-container span.fancytree-title:hover {
  background-color: #f5f5f5;
}
/*.fancytree-plain.fancytree-container span.fancytree-node.fancytree-active span.fancytree-title {
  background-color: #5094ce;
  color: #fff;
}
.fancytree-plain.fancytree-container span.fancytree-node.fancytree-active:hover span.fancytree-title {
  background-color: #3c87c8;
}*/
.fancytree-plain.fancytree-container.fancytree-ext-wide span.fancytree-node.fancytree-active {
  color: #fff;
}
/*.fancytree-plain.fancytree-container.fancytree-treefocus span.fancytree-node.fancytree-focused span.fancytree-title {
  border-color: #337ab7;
}
.fancytree-plain.fancytree-container.fancytree-treefocus span.fancytree-node.fancytree-active span.fancytree-title {
  background-color: #337ab7;
  border-color: #337ab7;
}
.fancytree-plain.fancytree-container.fancytree-treefocus span.fancytree-node.fancytree-active:hover span.fancytree-title {
  background-color: #2e6da4;
}*/
/*******************************************************************************
 * 'table' extension
 * Modifier classes on <table>:
 * table-hover                : Enable a light mouse hover effect
 * fancytree-colorize-selected: Give selected (checked) rows a color
 */
table.fancytree-ext-table > tbody > tr > td span.fancytree-title {
  border: none;
}
table.fancytree-ext-table.fancytree-colorize-selected > tbody > tr.fancytree-selected > td {
  background-color: #80c780;
}
table.fancytree-ext-table.fancytree-colorize-selected > tbody > tr.fancytree-selected > td,
table.fancytree-ext-table.fancytree-colorize-selected > tbody > tr.fancytree-selected > td span.fancytree-title {
  color: #fff;
}
/*table.fancytree-ext-table.fancytree-colorize-selected.fancytree-treefocus > tbody > tr.fancytree-selected > td {
  background-color: #5cb85c;
}*/
table.fancytree-ext-table.fancytree-colorize-selected.table-hover > tbody > tr.fancytree-selected:hover > td {
  background-color: #6ec06e;
}
/*table.fancytree-ext-table.fancytree-colorize-selected.fancytree-treefocus.table-hover > tbody > tr.fancytree-selected:hover > td {
  background-color: #4cae4c;
}
table.fancytree-ext-table.fancytree-colorize-selected.fancytree-treefocus.table-hover > tbody > tr.fancytree-selected.fancytree-active:hover > td,
table.fancytree-ext-table.fancytree-colorize-selected.table-hover > tbody > tr.fancytree-selected.fancytree-active:hover > td {
  background-color: #2e6da4;
}*/
table.fancytree-ext-table.fancytree-colorize-selected > tbody > tr.fancytree-active.fancytree-selected {
  outline-width: 2px;
  outline-offset: -2px;
  outline-style: solid;
  outline-color: #80c780;
}
/*table.fancytree-ext-table.fancytree-container > tbody > tr.fancytree-active > td {
  background-color: #5094ce;
}*/
table.fancytree-ext-table.fancytree-container > tbody > tr.fancytree-active > td,
table.fancytree-ext-table.fancytree-container > tbody > tr.fancytree-active > td span.fancytree-title {
  color: #fff;
}
/*table.fancytree-ext-table.fancytree-treefocus.fancytree-container > tbody > tr.fancytree-focused span.fancytree-title {
  outline: 1px dotted #000;
}
table.fancytree-ext-table.fancytree-treefocus.fancytree-container > tbody > tr.fancytree-active > td {
  background-color: #337ab7;
}
table.fancytree-ext-table.fancytree-treefocus.fancytree-container.table-hover > tbody > tr.fancytree-active:hover > td {
  background-color: #2e6da4;
}*/
