.tether-element, .tether-element:after, .tether-element:before, .tether-element *, .tether-element *:after, .tether-element *:before {
  box-sizing: border-box; }

.tether-element {
  position: absolute;
  display: none; }
  .tether-element.tether-open {
    display: block; }

.tether-element.tether-theme-arrows {
  max-width: 100%;
  max-height: 100%; }
  .tether-element.tether-theme-arrows .tether-content {
    border-radius: 5px;
    position: relative;
    font-family: inherit;
    background: #fff;
    color: inherit;
    padding: 1em;
    font-size: 1.1em;
    line-height: 1.5em;
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    -webkit-filter: drop-shadow(0 1px 4px rgba(0, 0, 0, 0.2));
            filter: drop-shadow(0 1px 4px rgba(0, 0, 0, 0.2)); }
    .tether-element.tether-theme-arrows .tether-content:before {
      content: "";
      display: block;
      position: absolute;
      width: 0;
      height: 0;
      border-color: transparent;
      border-width: 16px;
      border-style: solid; }
  .tether-element.tether-theme-arrows.tether-element-attached-bottom.tether-element-attached-center .tether-content {
    margin-bottom: 16px; }
    .tether-element.tether-theme-arrows.tether-element-attached-bottom.tether-element-attached-center .tether-content:before {
      top: 100%;
      left: 50%;
      margin-left: -16px;
      border-top-color: #fff; }
  .tether-element.tether-theme-arrows.tether-element-attached-top.tether-element-attached-center .tether-content {
    margin-top: 16px; }
    .tether-element.tether-theme-arrows.tether-element-attached-top.tether-element-attached-center .tether-content:before {
      bottom: 100%;
      left: 50%;
      margin-left: -16px;
      border-bottom-color: #fff; }
  .tether-element.tether-theme-arrows.tether-element-attached-right.tether-element-attached-middle .tether-content {
    margin-right: 16px; }
    .tether-element.tether-theme-arrows.tether-element-attached-right.tether-element-attached-middle .tether-content:before {
      left: 100%;
      top: 50%;
      margin-top: -16px;
      border-left-color: #fff; }
  .tether-element.tether-theme-arrows.tether-element-attached-left.tether-element-attached-middle .tether-content {
    margin-left: 16px; }
    .tether-element.tether-theme-arrows.tether-element-attached-left.tether-element-attached-middle .tether-content:before {
      right: 100%;
      top: 50%;
      margin-top: -16px;
      border-right-color: #fff; }
  .tether-element.tether-theme-arrows.tether-element-attached-left.tether-target-attached-center .tether-content {
    left: -32px; }
  .tether-element.tether-theme-arrows.tether-element-attached-right.tether-target-attached-center .tether-content {
    left: 32px; }
  .tether-element.tether-theme-arrows.tether-element-attached-top.tether-element-attached-left.tether-target-attached-middle .tether-content {
    margin-top: 16px; }
    .tether-element.tether-theme-arrows.tether-element-attached-top.tether-element-attached-left.tether-target-attached-middle .tether-content:before {
      bottom: 100%;
      left: 16px;
      border-bottom-color: #fff; }
  .tether-element.tether-theme-arrows.tether-element-attached-top.tether-element-attached-right.tether-target-attached-middle .tether-content {
    margin-top: 16px; }
    .tether-element.tether-theme-arrows.tether-element-attached-top.tether-element-attached-right.tether-target-attached-middle .tether-content:before {
      bottom: 100%;
      right: 16px;
      border-bottom-color: #fff; }
  .tether-element.tether-theme-arrows.tether-element-attached-bottom.tether-element-attached-left.tether-target-attached-middle .tether-content {
    margin-bottom: 16px; }
    .tether-element.tether-theme-arrows.tether-element-attached-bottom.tether-element-attached-left.tether-target-attached-middle .tether-content:before {
      top: 100%;
      left: 16px;
      border-top-color: #fff; }
  .tether-element.tether-theme-arrows.tether-element-attached-bottom.tether-element-attached-right.tether-target-attached-middle .tether-content {
    margin-bottom: 16px; }
    .tether-element.tether-theme-arrows.tether-element-attached-bottom.tether-element-attached-right.tether-target-attached-middle .tether-content:before {
      top: 100%;
      right: 16px;
      border-top-color: #fff; }
  .tether-element.tether-theme-arrows.tether-element-attached-top.tether-element-attached-left.tether-target-attached-bottom .tether-content {
    margin-top: 16px; }
    .tether-element.tether-theme-arrows.tether-element-attached-top.tether-element-attached-left.tether-target-attached-bottom .tether-content:before {
      bottom: 100%;
      left: 16px;
      border-bottom-color: #fff; }
  .tether-element.tether-theme-arrows.tether-element-attached-top.tether-element-attached-right.tether-target-attached-bottom .tether-content {
    margin-top: 16px; }
    .tether-element.tether-theme-arrows.tether-element-attached-top.tether-element-attached-right.tether-target-attached-bottom .tether-content:before {
      bottom: 100%;
      right: 16px;
      border-bottom-color: #fff; }
  .tether-element.tether-theme-arrows.tether-element-attached-bottom.tether-element-attached-left.tether-target-attached-top .tether-content {
    margin-bottom: 16px; }
    .tether-element.tether-theme-arrows.tether-element-attached-bottom.tether-element-attached-left.tether-target-attached-top .tether-content:before {
      top: 100%;
      left: 16px;
      border-top-color: #fff; }
  .tether-element.tether-theme-arrows.tether-element-attached-bottom.tether-element-attached-right.tether-target-attached-top .tether-content {
    margin-bottom: 16px; }
    .tether-element.tether-theme-arrows.tether-element-attached-bottom.tether-element-attached-right.tether-target-attached-top .tether-content:before {
      top: 100%;
      right: 16px;
      border-top-color: #fff; }
  .tether-element.tether-theme-arrows.tether-element-attached-top.tether-element-attached-right.tether-target-attached-left .tether-content {
    margin-right: 16px; }
    .tether-element.tether-theme-arrows.tether-element-attached-top.tether-element-attached-right.tether-target-attached-left .tether-content:before {
      top: 16px;
      left: 100%;
      border-left-color: #fff; }
  .tether-element.tether-theme-arrows.tether-element-attached-top.tether-element-attached-left.tether-target-attached-right .tether-content {
    margin-left: 16px; }
    .tether-element.tether-theme-arrows.tether-element-attached-top.tether-element-attached-left.tether-target-attached-right .tether-content:before {
      top: 16px;
      right: 100%;
      border-right-color: #fff; }
  .tether-element.tether-theme-arrows.tether-element-attached-bottom.tether-element-attached-right.tether-target-attached-left .tether-content {
    margin-right: 16px; }
    .tether-element.tether-theme-arrows.tether-element-attached-bottom.tether-element-attached-right.tether-target-attached-left .tether-content:before {
      bottom: 16px;
      left: 100%;
      border-left-color: #fff; }
  .tether-element.tether-theme-arrows.tether-element-attached-bottom.tether-element-attached-left.tether-target-attached-right .tether-content {
    margin-left: 16px; }
    .tether-element.tether-theme-arrows.tether-element-attached-bottom.tether-element-attached-left.tether-target-attached-right .tether-content:before {
      bottom: 16px;
      right: 100%;
      border-right-color: #fff; }
