﻿//(comment)/  <reference path="../lib/ui.fancytree.css">
/// <reference path="../lib/fancytree-2.38.0/skin-bootstrap/ui.fancytree.css">
/// <reference path="../quantalys/_variables.scss">
/// <reference path="../quantalys/_mixins.scss">

ul.fancytree-container{
    border: none;
    padding: 3px;
    padding-right: 10px;
    overflow:visible;
    font-family:Arial;
    font-size:13px;
}

ul.fancytree-container ul {
  padding: 0 0 0 0.7em;
}

.fancytree-plain.fancytree-container span.fancytree-node{
    margin-top: 0;
    margin-bottom: 0;
    height: 18px;
}

span.fancytree-title{
    margin-left: 0;
}

span.fancytree-checkbox {
    margin-left: 0.2em;
}

.fancytree-pinned {
    font-weight:bold;
    padding-top:5px;
    padding-bottom:5px;
}

.fancytree-plain.fancytree-container span.fancytree-title{
    outline: none;
    line-height: 18px;
}

/*V2*/

/*span.fancytree-empty,
span.fancytree-vline,
span.fancytree-expander,
span.fancytree-icon,
span.fancytree-checkbox,
span.fancytree-radio,
span.fancytree-drag-helper-img,
#fancytree-drop-marker {
    background-image: url("/images/sprites/sprite-dynatree.gif");
    width: 13px;
    height: 13px;
    display: inline-block;
    vertical-align: middle;
    background-repeat: no-repeat;
    background-position: left;
    background-position: -1px -1px;
}

.fancytree-exp-e span.fancytree-expander{
  background-image: none;
  cursor: default;
}*/
