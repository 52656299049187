@import "bootstrap/variables";

//Original libraries files
// /!\ Ne pas mettre les .css à la fin !!
@import "lib/jquery-ui";
@import "lib/ui.fancytree";
@import "lib/dataTables.bootstrap";
@import "lib/bootstrap-datepicker3";
@import "lib/amcharts";
@import "lib/spectrum";
@import "lib/codemirror/codemirror";
@import "lib/codemirror/3024-night";
@import "lib/dragula";
@import "lib/tether/tether";
@import "lib/tether/tether-theme-arrows";
@import "lib/tether/tether-theme-arrows-dark";
@import "lib/tether/drop-theme-arrows";
@import "lib/tether/drop-theme-arrows-bounce";
@import "lib/tether/drop-theme-arrows-bounce-dark";

//Font-awesome
@import "../fontawesome-pro-5.15.1-web/scss/fontawesome.scss";
@import "../fontawesome-pro-5.15.1-web/scss/brands.scss";
@import "../fontawesome-pro-5.15.1-web/scss/v4-shims.scss";

// Il faut choisir un seul style { Duotone | Light | Regular | Solid }
@import "../fontawesome-pro-5.15.1-web/scss/solid.scss";
//@import "../fontawesome-pro-5.15.1-web/scss/duotone.scss";
//@import "../fontawesome-pro-5.15.1-web/scss/light.scss";
//@import "../fontawesome-pro-5.15.1-web/scss/regular.scss";

//Variables
@import "quantalys/_variables.scss";
@import "quantalys/_mixins.scss";

//Custom redefinitions
@import "libcustom/_ui.fancytree.scss";
